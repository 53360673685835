<template lang="pug">
div
  .container-fluid
    .row
      .col
        h2.text-center {{plateName}}
    .row
      .d-xs-none.col-sm-1.col-md.col-lg
      .col-6.col-sm-4.col-md-4.col-lg-3
        .form-group
          label(for="house") Номер будинку
          input#house.form-control(type="text", maxlength="12", v-model='store.inputNumber')
      .col-3.col-sm-3.col-md-3.col-lg-2
        .form-group.street-num
          label(for="house-arrow-left") ←
          input#house-arrow-left.form-control(type="text" maxlength="3" v-model='store.leftNumber')
          //- small.form-text.text-muted Перший на&nbsp;вулиці
      .col-3.col-sm-3.col-md-3.col-lg-2
        .form-group.street-num
          label(for="house-arrow-right") →
          input#house-arrow-right.form-control(type="text" maxlength="3" v-model='store.rightNumber')
          //- small.form-text.text-muted Останній на&nbsp;вулиці
      .d-xs-none.col-sm-1.col-md.col-lg

  BulkDownloadList()

  .container-fluid
    .row
      .col.preview
        // SVG defs
        svg(
          width="0",
          height="0",
          viewBox="0,0,0,0",
          viewport xmlns="http://www.w3.org/2000/svg"
        )
          SvgDefs(            
              :plateWidth = "this.plateWidth"            
              :clipWidth = '80'
              )
        // SVG Placeholder
        #containerPlaceholder(
          v-if="isPlaceholder"
          )
          svg#svgPlaceholder(width="100%", :height="sizeM(240)",:viewBox="sizeM(-20) +' '+ sizeM(-20) +' '+ sizeM(360)+ ' '+ sizeM(360)", :class="{ material: isMaterial}" @click="isMaterial = !isMaterial", viewport xmlns="http://www.w3.org/2000/svg")              

            rect.plate(:width="sizeM(320)",:height="sizeM(320)",x="0" y="0" rx="16" ry="16" fill="#fff" stroke="#000" stroke-width=".25")
            
            g.refElements(style="display:none;")
              rect(ref="refLimit",:width="sizeM(sizeXR(0)-this.plateLimit)",:height="sizeM(320)",:x="sizeM(this.plateLimit/2)" y="0" fill="none" stroke="none")
              rect(ref="refOffset",:width="sizeM(this.plateOffset)",:height="sizeM(320)",x="0" y="0" fill="none" stroke="none")
            
            g(:transform="'translate('+ sizeM(0) +','+ sizeM(170) +')'")
              text.refDigit(:x="sizeM(320/2)",y="0", :font-size="sizeM(176)" letter-spacing="0.01em" dominant-baseline="text-top", text-anchor="middle", fill="#000" style="font-weight: 400; font-family: DniproCity-Medium;") {{mainNumPlace}}      
            
            g.wave(
              :transform="'translate('+ sizeM(40) +','+ sizeM(214) +') scale('+ sizeM(1) +')'"
              )
              SvgWave(
                :plateWidth = "this.plateWidth" 
                :clipWidth = "80"
                )

            g.arrow(v-if="store.leftNumber.length > 0 || isPlaceholder", :transform="'translate('+ sizeM(40) +','+ sizeM(258) +') scale('+ sizeM(.50) +')'")
              //- circle(cx="0" cy="0" r="4" fill="#ff0000")
              path(d="M3.5 18 0 21.5v.1l21.5 21.5 5.4-5.5L12.7 25H66v-7H12.7L26.9 5.5 21.5 0l-18 18z" fill="#0075c9" transform="scale(1, 1)")      
            text.first(:x="sizeM(80)",:y="sizeM(284)",:font-size="sizeM(42)",text-anchor="start" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .018em; fill: #000;") {{leftNumPlace}}

            g.arrow(v-if="store.rightNumber.length > 0 || isPlaceholder",:transform="'translate('+ sizeM(280) +','+ sizeM(258) +') scale('+ sizeM(.50) + ')'")
              //- circle(cx="0" cy="0" r="4" fill="#ff0000")
              path(d="M3.5 18 0 21.5v.1l21.5 21.5 5.4-5.5L12.7 25H66v-7H12.7L26.9 5.5 21.5 0l-18 18z" fill="#0075c9" transform="scale(-1, 1)")
            text.last(:x="sizeM(240)",:y="sizeM(284)",:font-size="sizeM(42)", text-anchor="end" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .018em; fill: #000;") {{rightNumPlace}}
            
            g#svgMaterial(style="display: none;",:transform="'scale('+ sizeM(1) +')'")
              polygon.shade(points="0 0, 100 160, 0 320" fill="none")
              g(transform="translate(0,0)")
                polygon.shade(points="320 0, 220 160, 320 320" fill="red" stroke-width="3" stroke="red")
              rect.reflex(width="320",height="320" rx="16" ry="16" fill="none")

            g#fix(v-if ="store.fixersDisplay") 
              circle(cx="16" cy="16" r="3" fill="#ccc")
              circle(:cx="sizeM(sizeXR(0))-16",cy="16" r="3" fill="#ccc")
              circle(:cx="sizeM(sizeXR(0))-16",:cy="sizeM(320)-16",r="3" fill="#ccc")
              circle(cx="16",:cy="sizeM(320)-16",r="3" fill="#ccc") 

        // SVG Template
        #containerTemplate(
          style="position: relative;"
          v-else)

          .cartPreview(
            :class="{ collection: isCollection}",
            ref="refCart",
            :style="'top:'+ this.rectY +'px; right: calc(100vw - '+ this.rectR +'px); width:'+ this.rectW +'px; height:'+ this.rectH +'px;'", v-html="animationBuff"         
            )

          svg#svgTemplate(
            width="100%", 
            :height="sizeM(240)",
            :viewBox="sizeM(-20) +' '+ sizeM(-20) +' '+ sizeM(sizeXR(0)+40)+ ' '+ sizeM(360)", 
            :class="{ material: isMaterial}", 
            @click="isMaterial = !isMaterial", 
            viewport xmlns="http://www.w3.org/2000/svg",
            preserveAspectRatio="xMidYMin meet")
            
            rect.plate(
              :width="sizeM(sizeXR(0))",
              :height="sizeM(320)",
              x="0",
              y="0",
              rx="16",
              ry="16",              
              fill="#fff",
              stroke="#000",
              stroke-width=".25", 
              ref="refReady")
            
            g#refElements(style="display:block;")
              rect(ref="refLimit",:width="sizeM(sizeXR(0)-this.plateLimit)",:height="sizeM(320)",:x="sizeM(this.plateLimit/2)" y="0" fill="none" stroke="none")
              rect(ref="refOffset",:width="sizeM(this.plateOffset)",:height="sizeM(320)",x="0" y="0" fill="none" stroke="none")
            
            g(:transform="'translate('+ sizeM(6) +','+ sizeM(170) +')'")
              BuildingNumber(ref="refDigit", :sizeMX = "sizeM(sizeXR(0)/2)", :sizeMFont = "sizeM(176)", anchor = "middle", fillColor = "#000")       

            g.wave(
              :transform="'translate('+ sizeM(40) +','+ sizeM(214) +') scale('+ sizeM(1) +')'"
              )
              SvgWave(
                :plateWidth = "this.plateWidth" 
                :clipWidth = "80"
                )

            g.arrow(v-if="store.leftNumber.length > 0 || isPlaceholder", :transform="'translate('+ sizeM(40) +','+ sizeM(258) +') scale('+ sizeM(.50) +')'")
              //- circle(cx="0" cy="0" r="4" fill="#ff0000")
              path(d="M3.5 18 0 21.5v.1l21.5 21.5 5.4-5.5L12.7 25H66v-7H12.7L26.9 5.5 21.5 0l-18 18z" fill="#0075c9" transform="scale(1, 1)")      
            text.first(:x="sizeM(80)",:y="sizeM(284)",:font-size="sizeM(42)",text-anchor="start" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .018em; fill: #000;") {{store.leftNumber}}

            g.arrow(v-if="store.rightNumber.length > 0 || isPlaceholder",:transform="'translate('+ sizeM(sizeXR(40)) +','+ sizeM(258) +') scale('+ sizeM(.50) + ')'")
              //- circle(cx="0" cy="0" r="4" fill="#ff0000")
              path(d="M3.5 18 0 21.5v.1l21.5 21.5 5.4-5.5L12.7 25H66v-7H12.7L26.9 5.5 21.5 0l-18 18z" fill="#0075c9" transform="scale(-1, 1)")
            text.last(:x="sizeM(sizeXR(80))",:y="sizeM(284)",:font-size="sizeM(42)", text-anchor="end" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .018em; fill: #000;") {{store.rightNumber}}
            
            g.svgMaterial(style="display: none;",:transform="'scale('+ sizeM(1) +')'")
              polygon.shade(points="0 0, 100 160, 0 320" fill="none")
              g(:transform="'translate('+ sizeXR(320) +',0)'")
                polygon.shade(points="320 0, 220 160, 320 320" fill="red" stroke-width="3" stroke="red")
              rect.reflex(:width="sizeXR(0)",height="320" rx="16" ry="16" fill="none")

            g.fix(v-if ="store.fixersDisplay") 
              circle(cx="16" cy="16" r="3" fill="#ccc")
              circle(:cx="sizeM(sizeXR(0))-16",cy="16" r="3" fill="#ccc")
              circle(:cx="sizeM(sizeXR(0))-16",:cy="sizeM(320)-16",r="3" fill="#ccc")
              circle(cx="16",:cy="sizeM(320)-16",r="3" fill="#ccc")          

            SvgRequirements(            
              :plateWidth = "this.plateWidth"
              :plateHeight = "this.plateHeight"
              :plateName = "this.plateName"
              :isWhiteBack = "true"
              :immutableSize = "false"
              )

          

        .sign-size.text-center 
          span розмір покажчика
          | {{sizeM(sizeXR(0))}}x{{sizeM(320)}} мм

  .container-fluid
    .row.row-cols-1.plate-options
      .col-xs-12.col-sm-8.offset-sm-2.col-md-6.offset-md-3
        .form-group
          .custom-control.custom-switch
            input.custom-control-input(type="checkbox" id="largerSize" v-model="store.sizeMultiplier")
            label.custom-control-label(for="largerSize") збільшений розмір покажчика
              br
              small.caption Якщо відстань від фасаду до краю хідника більше ніж&nbsp;15&nbsp;метрів      
      
        .form-group
          .custom-control.custom-switch
            input#fixMarks.custom-control-input(type="checkbox" v-model="store.fixersDisplay")
            label.custom-control-label(for="fixMarks") показати кріплення
    
    .row.plate-download
      .col.text-center
        button#btn-download.btn.btn-primary.btn-lg(type="button", :disabled="generateNotReady", @click="convertFunc(false)") Завантажити                
        button#add-cart.btn.btn-primary.btn-lg(type="button", :disabled="generateNotReady", @click="convertFunc(true)") Додати до набору
    .row.plate-download
      .col-xs-12.col-sm-8.offset-sm-2.col-md-6.offset-md-3.text-center        
        p.caption.text-center Макет у форматі .pdf з літерами у кривих
          br
          br
          | Під час завантаження матеріалів ви погоджуєтесь з ліцензією 
          a(href="https://creativecommons.org/licenses/by-nd/4.0/deed.uk" target="_blank") CC BY-ND 4.0 Із 
          | Зазначенням Авторства — Без Похідних 4.0 Міжнародна
          
  .svgInstance 
      svg#svgInstance(:width="sizeM(sizeXR(0))", :height="sizeM(400)",:viewBox="'0 0 '+ sizeM(sizeXR(0)) +' '+ sizeM(500)", preserveAspectRatio="xMidYMin meet", viewport xmlns="http://www.w3.org/2000/svg")
        

</template>

<script>
import { store } from '@/store.js';

import * as pdfFuncs from '@/svgToPdfConvertJS.js';

import BuildingNumber from '@/components/BuildingNumber.vue'
import SvgRequirements from '@/components/SvgRequirements.vue'
import SvgDefs from '@/components/SvgDefs.vue'
import SvgWave from '@/components/SvgWave.vue'
import BulkDownloadList from '@/components/BulkDownloadList.vue'

export default {
  name: 'Number',
  components: {
    BuildingNumber,
    SvgRequirements,
    SvgDefs,
    SvgWave,
    BulkDownloadList,
  },

  data(){
    return{
      store,

      mainNumPlace: '42',
      leftNumPlace: '1',
      rightNumPlace: '144',

      isMaterial: false,
      isPlaceholder: true,
      generateNotReady: true,
      isCollection: false,

      plateWidth:320,
      plateWidthMin:320,
      plateHeight:320,
      plateOffset:80,
      plateLimit:70,

      rectR: 0,
      rectY: 0,
      rectW: 0,
      rectH: 0,

      plateName: 'Номерний покажчик',

      animationBuff: 0,
    }
  },

  watch:
  {
    'store.inputNumber': function ()
		{
      this.minReq();      
		},

    'store.leftNumber': function ()
		{
      this.minReq();      
		},

    'store.rightNumber': function ()
		{
      this.minReq();
		},

    'store.bulkData.length': function()
    {
      if(this.store.bulkData.length > 0)
      {
        this.store.isCartEmpty = false;
      }
      else
      {
        this.store.isCartEmpty = true;
      }
    },
  },

  methods:
  {     
    minReq()
    {
      if(this.store.inputNumber == '' && this.store.leftNumber == '' && this.store.rightNumber == '')
      {
        this.isPlaceholder = true;
        this.isMaterial = false;        
      }      
      else
      {
        this.isPlaceholder = false;
        this.isMaterial = true;
      }

      if(this.store.inputNumber != '' && (this.store.leftNumber != '' || this.store.rightNumber != ''))
      {
        this.generateNotReady = false;
      }
      else 
      {
        this.generateNotReady = true;
      }
      
    },

    convertFunc(isBulk)
    {
      let pdfMainName = (this.store.mainNumber).toString() + '_' + (this.store.leftNumber).toString() + '-' + (this.store.rightNumber).toString();
      let pdfNameOptions = '_number-sign';
      if(this.store.sizeMultiplier)
      {
        pdfNameOptions += '_XL';
      }
      if(this.store.fixersDisplay)
      {
        pdfNameOptions += '_FIX';
      }
      pdfNameOptions += '.pdf';

      pdfFuncs.svgToPdfCovert(10, 10, this.sizeM(this.plateWidth), this.sizeM(500), this.sizeM(this.plateWidth)+20, this.sizeM(480)+30, 
      pdfMainName, pdfNameOptions, this.sizeM(30), this.sizeM(330)+20, this.sizeM(this.plateHeight), 'svgInstance', 'svgTemplate', isBulk, this.store.bulkData);

      if(isBulk)
      {
        this.getPosition();

        this.animationBuff = document.querySelector('#svgTemplate').outerHTML;

        let isCopy = false;

        if(typeof this.store.bulkData.length !== 'undefined')
        {
            for(let i = 0; i < this.store.bulkData.length; i++)
            {
                if(this.store.bulkData[i].pdfName == (pdfMainName + pdfNameOptions))
                {
                    isCopy = true;
                }  
            }
        }

        if(!isCopy)
        {
          setTimeout(() => {
            this.isCollection = true;
          }, 100); 
          setTimeout(() => {
            this.animationBuff = 0;
            this.isCollection = !this.isCollection;        
          }, 1000); 
        }
      }
    },
    
    getPosition() {      
      this.rectY = parseInt(this.$refs.refReady.getBoundingClientRect().y);
      this.rectW = parseInt(this.$refs.refReady.getBoundingClientRect().width);
      this.rectH = parseInt(this.$refs.refReady.getBoundingClientRect().height);
      this.rectR = parseInt(this.$refs.refReady.getBoundingClientRect().right);        
    },

    sizeM(size)
    {
      if(this.store.sizeMultiplier)
      {
        return size;
      }
      else
      {
        return size * 0.75;
      }
    },
    sizeXR(offset)
    {
      return this.plateWidth - offset;
    },

    getWidth() {      
      var limitBox = parseInt(this.$refs.refLimit.getBoundingClientRect().width);
      var offsetBox = parseInt(this.$refs.refOffset.getBoundingClientRect().width);
      var contentBox = parseInt(this.$refs.refDigit.$el.getBoundingClientRect().width);

      if(limitBox < contentBox) 
      {       
        return this.plateWidth + this.plateOffset;
      }
      else if ((limitBox > contentBox) && (limitBox - contentBox > offsetBox) && this.sizeM(this.plateWidth) > this.sizeM(this.plateWidthMin))
      {
        return this.plateWidth - this.plateOffset;        
      }      
      else
      {
        return this.plateWidth;
      }
    },
  },

  mounted() {
    this.minReq();
  },  

  updated(){
    this.$nextTick(()=>{
      if(!this.isPlaceholder) {
        this.plateWidth = this.getWidth();            
      }
    })    
  },  
}

</script>

<style lang="scss" scoped>
@import '@/assets/styles/_svg-templates.scss';
@import '@/assets/styles/_svg-textures.scss';

// Scoped
.preview {
  min-height: 320px;
  @include respond-below(md) {
    min-height: 240px;
  }
}

.blocks { 
  padding: 20px 40px 0;
  box-sizing: padding-box;
}

.container-fluid {
  max-width: 600px;
}

.cartPreview {  
  display: inline-block; 
  width: 300px;
  height: 160px;

  position: fixed;
  top: 200px;
  right: calc(50% - 300px);
  
  visibility: hidden;
  opacity: 1;
  background-color: transparent;
  border-radius: 8px;
  box-shadow: 0 4px 80px 0 rgba(0,0,0,.3);  
  border: none;

  &.collection {    
    visibility: visible;
    top: 50px !important;
    right: 50px !important;   
    
    opacity: 0;
    box-shadow: 0 4px 20px 0 rgba(0,0,0,.3);

    transform: scale(.1);
    transform-origin: top right;

    transition: top .5s cubic-bezier(0, 0, 0.3, 1.8), right .5s ease-out, transform .5s ease-out, opacity .75s ease-in, box-shadow .5s ease-out;

  }
}

</style>